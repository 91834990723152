 
import React from 'react'
import {Divider, Tooltip} from '@material-ui/core'
import {Check, Pause, DoNotDisturb, MoreVert} from '@mui/icons-material';
import {Menu, MenuItem, IconButton} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

export default function ActionMenu ({ status, onAccept, onHold, onDeny, onSilentlyHold, onSilentlyDeny, acceptWarning }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const listItemTextStyle = {fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight: 'bold'}
    const listItemIconStyle = {minWidth:'30px', marginBottom:'4px'}
  
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <>
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
           <MenuItem disabled={status === 'accepted'} onClick={() => {onAccept(); handleMenuClose();}}>
              <ListItemIcon style={listItemIconStyle}>
                <Check htmlColor={status === 'accepted' ? '' : acceptWarning ? 'red' : 'green'}/>
              </ListItemIcon>
              <ListItemText>
                <Tooltip title={acceptWarning ? acceptWarning : 'Accept'}>
                    <span style={acceptWarning ? {color:'red'} : {} }>Accept</span>
                </Tooltip>
              </ListItemText>
          </MenuItem>

          <MenuItem disabled={status === 'hold'} onClick={() => {onHold(); handleMenuClose();}}>
              <ListItemIcon style={listItemIconStyle}>
                <Pause htmlColor={status === 'hold' ? '' : 'darkgoldenrod'}/>
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Hold</span></ListItemText>
          </MenuItem>
          <MenuItem disabled={status === 'denied'} onClick={() => {onDeny(); handleMenuClose();}}>
              <ListItemIcon style={listItemIconStyle}>
                <DoNotDisturb htmlColor={status === 'denied' ? '' : 'red'}/>
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Deny</span></ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => {onSilentlyHold(); handleMenuClose();}}>
              <ListItemIcon style={listItemIconStyle}>
                <Pause fontSize="small" />
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Silently Hold</span></ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {onSilentlyDeny(); handleMenuClose();}}>
              <ListItemIcon style={listItemIconStyle}>
                <DoNotDisturb fontSize="small" />
              </ListItemIcon>
              <ListItemText><span style={listItemTextStyle}>Silently Deny</span></ListItemText>
          </MenuItem>
          
        </Menu>
      </>
    );
  };