import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {noop, get, isEmpty, filter, head} from "lodash/fp";
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import { ACCEPTED } from '../../../consts/status.js'
import Unauthorized from "../../Unauthorized";
import {getSelectedTeam,getSelectedTeamsPairings } from '../../../reducers/teamSelectors'
import { getIsDocumentSubmissionOpen } from '../../../reducers/mootSelectors'
import {CircularProgress} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../common/TabPanel";
import { TEAM_ROLE_TYPE } from '../../../utils/constants'
import * as actions from "../../../actions/teamActions";
import UploadDocument from "./uploadDocument";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import {format, utcToZonedTime} from "date-fns-tz";
import {makeStyles} from "@material-ui/core/styles";
import TeamName from "../TeamName";
import {shouldOverride} from "../../helpers";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))
const WrittenSubmissions = ({ router,
                                team,
                                getSelectedTeam = noop,
                                getIsDocumentSubmissionOpen = noop,
                                isTeamDocumentSubmissionOpen = false,
                                getSelectedTeamsPairings = noop,
                                selectedTeamsPairings = [],                                
                                currentMoot,
                                getCurrentMoot = noop }) => {

    const [isLoading,setIsLoading] = useState(true);
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)
    const handleTabChange = (event, newValue) => setTabValue(newValue)

    const override = router.location.query.override

    useEffect(() => {
        async function getData() {
            await getSelectedTeam()
            await getCurrentMoot()
            await getIsDocumentSubmissionOpen()
            await getSelectedTeamsPairings()
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isAllowed = get('status',team) === ACCEPTED
    //This teams respondent is the pairing where this team is the claimant
    //const selectedTeamsRespondent = head(filter(p => p.claimant.id === team.id,selectedTeamsPairings))
    //This teams claimant is the pairing where this team is the respondent
    const selectedTeamsClaimant = head(filter(p => p.respondent.id === team.id,selectedTeamsPairings))
    
    return (
        <Container style={{paddingBottom: '10rem', paddingTop: '4rem'}}>
             { isLoading &&
                <CircularProgress />
            }
            {/*{((!isLoading && !isAllowed && !isTeamDocumentSubmissionOpen.claimantNotOpenYet && isTeamDocumentSubmissionOpen.claimantClosed) ||
            (!isLoading && !isAllowed && !isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed)) &&
                <Unauthorized />
            }*/}
            {!isLoading && !isAllowed &&
                <Unauthorized />
            }
                    
            {!isLoading && isAllowed &&
            <Container maxWidth='xl' className={classes.root} style={{padding: '0'}}>
                <Typography variant='h4'>Written Submissions</Typography>

                    <Tabs value={tabValue} onChange={handleTabChange} style={{ borderBottom: '1px solid #e0e0e0'}}>
                        <Tab label='Claimant Memorandum' />
                        <Tab label='Respondent Memorandum' />
                    </Tabs>

                <TabPanel value={tabValue} index={0} style={{ margin: '2rem 0'}}>
                    {/* When claimant submission is not yet open */}
                    {isTeamDocumentSubmissionOpen.claimantNotOpenYet && !shouldOverride(override,currentMoot) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Claimant's Memorandum is due by {format(utcToZonedTime(currentMoot.claimantDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy HH:mm')} (HKT).</Typography>
                            <Typography style={{ margin: '1rem 0'}}>You will be able to upload your Claimant's Memorandum through your Team Account shortly.</Typography>
                        </>       
                    }                 
                    {/* When claimant submission is open */}
                    {!isTeamDocumentSubmissionOpen.claimantNotOpenYet && !isTeamDocumentSubmissionOpen.claimantClosed && !shouldOverride(override,currentMoot) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the <a href="https://cisgmoot.org/rules" target="_blank" className="color-blue" rel="noreferrer">{ordinal(currentMoot.mootNumber)} VEM Rules</a> and <a href="https://cisgmoot.org/key-dates-times" target="_blank" className="color-blue" rel="noreferrer">Key Dates and Times</a>, your team must submit a memorandum in support of the claimant's position by Thursday, 12 December 2024 23:59 (HKT). Please ensure that your submitted Claimant's Memorandum is in accordance with the formatting requirements set out in the {ordinal(currentMoot.mootNumber)} VEM Rules. You may upload as many versions of your Claimant's Memorandum to your Team Account. However, the <strong>last version</strong> submitted by the submission deadline will be deemed the final version submitted by the team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>Failure to submit a Claimant's Memorandum within the deadline will result in <strong>automatic disqualification</strong> of your team from the {ordinal(currentMoot.mootNumber)} Vis East Moot and immediate forfeiture of your non-refundable deposit.
                            </Typography>                            
                        </>
                    }                    
                    {/* When claimant submission is closed */}
                    {!isTeamDocumentSubmissionOpen.claimantNotOpenYet && isTeamDocumentSubmissionOpen.claimantClosed && !shouldOverride(override,currentMoot) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>The deadline to submit your Claimant's Memorandum has expired.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the {ordinal(currentMoot.mootNumber)} VEM Rules, the last version of your submission uploaded to your Team Account before the deadline is deemed the final version submitted by your team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>You will be able to access the claimant memorandum to which you will submit your Respondent's Memorandum within two (2)  weeks after the deadline for submission of the Claimant's Memorandum.</Typography>
                        </>
                    }
                    <UploadDocument isOpen={((!isTeamDocumentSubmissionOpen.claimantNotOpenYet && !isTeamDocumentSubmissionOpen.claimantClosed) || shouldOverride(override,currentMoot))}  isAvailableCritique={isTeamDocumentSubmissionOpen.critiqueIsAvailable} type={TEAM_ROLE_TYPE.CLAIMANT} ordinalMootNumber={ordinal(currentMoot.mootNumber)}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1} style={{ margin: '2rem 0'}}>
                    {/* When respondent submission is not yet open */}
                    {(isEmpty(selectedTeamsPairings) || isTeamDocumentSubmissionOpen.respondentNotOpenYet) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Respondent's Memorandum is due by {format(utcToZonedTime(currentMoot.respondentDocumentUploadEndDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy HH:mm')} (HKT).</Typography>
                            <Typography style={{ margin: '1rem 0'}}>You will be able to upload your Respondent's Memorandum through your Team Account at a time after the Claimant’s Memorandum has been submitted and you have received the claimant’s memorandum to which you will submit your Respondent’s Memorandum.</Typography>
                        </>
                    }
                    {/* After it is available for upload */}
                    {!isTeamDocumentSubmissionOpen.respondentNotOpenYet && !isTeamDocumentSubmissionOpen.respondentClosed && !isEmpty(selectedTeamsPairings) &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>Pursuant to the {ordinal(currentMoot.mootNumber)} VEM Rules, your team must submit a memorandum in support of the respondent's position <u>in response to the claimant's memorandum</u> that you received. The deadline for such submission is {format(utcToZonedTime(currentMoot.respondentDocumentUploadEndDate,'Asia/Hong_Kong'), 'HH:mm')} (Hong Kong Time) on {format(utcToZonedTime(currentMoot.respondentDocumentUploadEndDate,'Asia/Hong_Kong'), 'dd MMMM yyyy')}. Please ensure that your submitted Respondent's Memorandum is in accordance with the formatting requirements set out under the Rules. You may upload as many versions of your Respondent's Memorandum to your Team Account. However, please note that the <strong>last version</strong> submitted by the submission deadline will be deemed the final version submitted by the team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>It is <u>absolutely essential</u> that your Respondent's Memorandum is responsive to all the arguments made in the memorandum for the claimant. Respondent's memorandum should also address issues your team believes should be raised, even if the argument was not raised by the claimant's memorandum.</Typography>                                                
                        </>
                    }
                    
                    {/* When respondent submission is closed */}
                    {!isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed &&
                        <>
                            <Typography style={{ margin: '1rem 0'}}>The deadline to submit your Respondent's Memorandum has elapsed. Pursuant to the Rules, the last version of your submission uploaded to your Team Account shall be deemed the final version submitted by your team.</Typography>
                            <Typography style={{ margin: '1rem 0'}}>Also pursuant to the Rules, you are now allowed to exchange your written memoranda with other teams in preparation for the oral hearings.</Typography>                            
                        </>
                    }
                    {!isEmpty(selectedTeamsPairings) && !isTeamDocumentSubmissionOpen.respondentNotOpenYet &&
                        <>
                            <Typography>{!isTeamDocumentSubmissionOpen.respondentNotOpenYet && isTeamDocumentSubmissionOpen.respondentClosed ? 'You responded to ' : 'Please respond to '} <strong><TeamName team={get('claimant',selectedTeamsClaimant)} /></strong> claimant memorandum <a href={get('claimantDocUrl',selectedTeamsClaimant)}>{get('claimantDocName',selectedTeamsClaimant)}</a> </Typography>
                            <UploadDocument isOpen={((!isTeamDocumentSubmissionOpen.respondentNotOpenYet && !isTeamDocumentSubmissionOpen.respondentClosed) || shouldOverride(override,currentMoot))} isAvailableCritique={isTeamDocumentSubmissionOpen.critiqueIsAvailable} type={TEAM_ROLE_TYPE.RESPONDENT} />
                        </>
                    }
                </TabPanel>
            </Container>
            }
        </Container>
    )
}


WrittenSubmissions.propTypes = {
    getSelectedTeam: PropTypes.func,
    team: PropTypes.object,
    claimant: PropTypes.object,
    getIsDocumentSubmissionOpen: PropTypes.func,
    isTeamDocumentSubmissionOpen: PropTypes.object,
    getSelectedTeamsPairings: PropTypes.func

}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        team: state.team.selectedTeam,
        isTeamDocumentSubmissionOpen: state.moot.currentMoot.isTeamDocumentSubmissionOpen,
        selectedTeamsPairings : state.team.selectedTeamsPairings,
        currentMoot: state.moot.currentMoot
    }),
    {
        getSelectedTeam: getSelectedTeam,
        getSelectedTeamsPairings: getSelectedTeamsPairings,
        getIsDocumentSubmissionOpen: getIsDocumentSubmissionOpen,
        getCurrentMoot: getCurrentMoot
    }
)(WrittenSubmissions)