import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, LicenseInfo } from '@mui/x-data-grid-pro';

import { format, parseISO } from 'date-fns'
import {isEmpty, noop, join} from 'lodash/fp'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAllArbitrators} from "../../../reducers/adminSelectors";
import {
  setArbitratorStatus,
  exportAllArbitratorConflicts
} from "../../../actions/adminActions";
import {SaveAlt} from "@material-ui/icons";
import {clientsideDownloadFile, useLocalStorage} from "../../helpers";
import ActionMenu from './ActionMenu';

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

  const ArbitratorTable = ({ arbitrators = [], getArbitrators = noop, setArbitratorStatus = noop, exportAllArbitratorConflicts = noop}) => {
    const [busy, setBusy] = React.useState(false)
    const [arbitratorColumns, setArbitratorColumns] = useLocalStorage("arbitratorColumns", "");


    const onSetArbitratorStatus = async (id, status, notify) => {
      try {
        setBusy(true)
        await setArbitratorStatus(id,status, notify)
        setBusy(false)
      }catch(e){
        //TODO ?
        setBusy(false)
      }
    }
    React.useEffect(() => {
      const asyncFetchData = async () => {
        try {
          setBusy(true)
          await getArbitrators()
          setBusy(false)
        }catch(e){
          //TODO ?
          setBusy(false)
        }
      }
      asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleColumnVisibilityChange = (params) => {    
      setArbitratorColumns(params);
    };
  
    const isHidden = (keyValue, hideByDefault) => {
      if(keyValue === true ) { // Column is visible
        return false;
      } else if(keyValue === false ) { // Column is not visible
        return true;
      } else if(hideByDefault) { // Column is not visible by default
        return true;
      } else {
        return false; // Column is visible
      }
    }


    const exportArbitratorConflicts = async () => {
      setBusy(true)
      const data = await exportAllArbitratorConflicts()
      const blob = new Blob([join('\n',data)], {
        type: 'text/tab-separated-values',
      });

      const fullName = `ArbitratorConflicts - ${format(new Date(),'yyyy-MM-dd')}.tsv`;

      clientsideDownloadFile(blob,fullName)
      setBusy(false)
    }
    function CustomToolbar() {
      return (
          <GridToolbarContainer style={{gap: '15px' }}>
            <GridToolbarColumnsButton className="toolbar-button" />
            <GridToolbarFilterButton className="toolbar-button" />
            <GridToolbarDensitySelector className="toolbar-button" />
            <Tooltip title='Export contains visible columns of the grid'>              
              <GridToolbarExport
                    className="toolbar-button"
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                        fileName: `Arbitrators - ${format(new Date(),'yyyy-MM-dd')}`,
                        utf8WithBom: true,
                    }}                  
              />
            </Tooltip>
            {/* Backend "Export Arbitrators" has been deprecated by DataGrid Export */}
            {/* <Button style={{display:'none'}} color="primary"
                    size="small"
                    startIcon={<SaveAlt />}
                    aria-haspopup="menu"
                    onClick={exportArbitrators}>Export Arbitrators</Button>  */}
            <Button color="primary"
                    size="small"
                    startIcon={<SaveAlt />}
                    aria-haspopup="menu"
                    onClick={exportArbitratorConflicts}
                    >Export Conflicts & Availability</Button>
          </GridToolbarContainer>
      );

      
    }

    const countNumber =  (count) => {
      if (count === '0-2') {
        return (0)
      } else if (count === '3-5') {
        return (3)
      } else if (count === '5-10') {
        return (5)
      } else if (count === '11-20') {
        return (11)
      } else if (count === '21+') {
        return (21)
      } else if (count === '1-3') {
        return (1)
      } else if (count === '4-9') {
        return (4)
      } else if (count === '10+') {
        return (10)
      } else {
        return count
      }
    }

    const availabilityForMemoCheck = (participation, availability) => {

      if (participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week' && availability) {
        return true
      } else if (participation === "I am available ONLY to rank written memoranda" && availability) {
        return true
      } else if (participation === "I am available to ONLY act as an arbitrator during oral arguments") {
        return false
      } else {
        return ''
      }



    } 

    const columns = [
      {
        field: 'id',
        headerName: 'Arbitrator Id',
        width: 100,
        align: 'right',
        hide: isHidden(arbitratorColumns.id, true)
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        width: 100,
        hide: isHidden(arbitratorColumns.createdAt, true),
        valueGetter: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd')
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        width: 100,
        hide: isHidden(arbitratorColumns.updatedAt, true),
        valueGetter: params => format(parseISO(params.row.updatedAt),'yyyy-MM-dd')
      },
      {
        field: 'bio',
        headerName: 'Bio',
        flex: 1,
        hide: isHidden(arbitratorColumns.bio, true),
      },
      {
        field: 'prefix',
        headerName: 'Prefix',
        width: 90,
        hide: isHidden(arbitratorColumns.prefix, true),
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        flex: 1,
        hide: isHidden(arbitratorColumns.firstName, true),   
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        flex: 1,
        hide: isHidden(arbitratorColumns.lastName, true),      
      },
      {
        field: 'contact',
        headerName: 'Contact',
        flex: 1,
        hide: isHidden(arbitratorColumns.contact),   
        valueGetter: params => params.row.firstName + ' ' + params.row.lastName
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        renderCell: params => (<a href={`mailto:${params.row.user.email}`}>{params.row.user.email}</a>),
        valueGetter: params => (params.row.user.email),
        hide: isHidden(arbitratorColumns.email, true)
      },
      {
        field: 'contactPhone',
        headerName: 'Phone',
        flex: 1,
        hide: isHidden(arbitratorColumns.contactPhone, true)
      },
      {
        field: 'street',
        headerName: 'Street',
        flex: 1,
        hide: isHidden(arbitratorColumns.street, true),
      },
      {
        field: 'unit',
        headerName: 'Unit',
        flex: 1,
        hide: isHidden(arbitratorColumns.unit, true)
      },
      {
        field: 'city',
        headerName: 'City',
        flex: 1,
        hide: isHidden(arbitratorColumns.city, true)
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 1,
        hide: isHidden(arbitratorColumns.state, true)
      },
      {
        field: 'zip',
        headerName: 'Postal Code',
        width: 100,
        hide: isHidden(arbitratorColumns.zip, true)
      },
      {
        field: 'nationality',
        headerName: 'Nationality',
        flex: 1,
        hide: isHidden(arbitratorColumns.nationality)
      },
      {
        field: 'gender',
        headerName: 'Gender',
        width: 90,
        hide: isHidden(arbitratorColumns.gender)
      },
      {
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
        flex: 1,
        hide: isHidden(arbitratorColumns.jurisdiction)
      },
      {
        field: 'organization',
        headerName: 'Organization',
        flex: 1,
        hide: isHidden(arbitratorColumns.organization, true)
      },
      {
        field: 'background',
        headerName: 'Legal Background',
        width: 140,
        hide: isHidden(arbitratorColumns.background)
      },
      {
        field: 'yearGraduated',
        headerName: 'Year Graduated',
        width: 130,
        hide: isHidden(arbitratorColumns.yearGraduated),
        valueGetter: params => !isEmpty(params.row.yearGraduated) ? format(parseISO(params.row.yearGraduated),'yyyy') : ''
      },
      {
        field: 'dateQualified',
        headerName: 'Qualified Law',
        width: 110,
        hide: isHidden(arbitratorColumns.dateQualified),
        valueGetter: params => !isEmpty(params.row.dateQualified) ? format(parseISO(params.row.dateQualified),'yyyy-MM') : ''
      },
      {
        field: 'newArbitrator',
        headerName: "New Arbitrator",
        width: 120,
        type: 'boolean',
        hide: isHidden(arbitratorColumns.newArbitrator),
        valueGetter: params => params.row.arbitrationCount === '0'
      },
      {
        field: 'arbitrationCount',
        headerName: "Arbitration Count",
        width: 130,
        hide: isHidden(arbitratorColumns.arbitrationCount, true),
        renderCell: params => (params.row.arbitrationCount),
        valueGetter: params => countNumber(params.row.arbitrationCount)
      },
      {
        field: 'participation',
        headerName: "Participation",
        width: 110,
        hide: isHidden(arbitratorColumns.participation, true),
        valueGetter: params => {
          if (params.row.participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week') {
            return 'both'
          } else if (params.row.participation === "I am available ONLY to rank written memoranda") {
            return 'written'
          } else if (params.row.participation === "I am available to ONLY act as an arbitrator during oral arguments") {
            return 'oral'
          } else if (params.row.participation === "I am no longer able to act as an arbitrator") {
            return 'unavailable'
          } else {
            return ''
          }
        }
      },
      {
        field: 'availableForClaimantEvaluation',
        headerName: "Available for Claimant Memoranda Evaluation",
        flex: 1,
        hide: isHidden(arbitratorColumns.availableForClaimantEvaluation, true),
        type: "boolean",
        valueGetter: params => availabilityForMemoCheck(params.row.participation, params.row.availableForClaimantEvaluation)
      },
      {
        field: 'availableForRespondentEvaluation',
        headerName: "Available for Respondent Memoranda Evaluation",
        flex: 1,
        hide: isHidden(arbitratorColumns.availableForRespondentEvaluation, true),
        type: "boolean",
        valueGetter: params => availabilityForMemoCheck(params.row.participation, params.row.availableForRespondentEvaluation)
      },
      {
        field: 'availableForRankingClaimant',
        headerName: "Available for Claimant Memoranda Final Ranking",
        flex: 1,
        hide: isHidden(arbitratorColumns.availableForRankingClaimant, true),
        type: "boolean",
        valueGetter: params => availabilityForMemoCheck(params.row.participation, params.row.availableForRankingClaimant)
      },
      {
        field: 'availableForRankingRespondent',
        headerName: "Available for Respondent Memoranda Final Ranking",
        flex: 1,
        hide: isHidden(arbitratorColumns.availableForRankingRespondent, true),
        type: "boolean",
        valueGetter: params => availabilityForMemoCheck(params.row.participation, params.row.availableForRankingRespondent)
      },      
      {
        field: 'roundsYouCanArbitrate',
        headerName: "Max rounds to arbitrate",
        flex: 1,
        align: 'right',
        hide: isHidden(arbitratorColumns.roundsYouCanArbitrate, true),
        valueGetter: params => {
          if (params.row.participation === "I am available ONLY to rank written memoranda") {
            return 0
          } else {
            return params.row.roundsYouCanArbitrate
          }
        }
      },
      {
        field: 'optInShareData',
        headerName: "Opt In Process",
        width: 130,
        hide: isHidden(arbitratorColumns.optInShareData, true)
      }, 
      {
        field: 'optInResearch',
        headerName: "Opt In Research",
        width: 130,
        hide: isHidden(arbitratorColumns.optInResearch, true)
      },
      {
        field: 'arbitrationCaseCount',
        headerName: "Arbitration Case Count",
        flex: 1,
        hide: isHidden(arbitratorColumns.arbitrationCaseCount, true),
        renderCell: params => (params.row.arbitrationCaseCount),
        valueGetter: params => countNumber(params.row.arbitrationCaseCount)
      },
      {
        field: 'counselCaseCount',
        headerName: "Counsel Case Count",
        flex: 1,
        hide: isHidden(arbitratorColumns.counselCaseCount, true),
        renderCell: params => (params.row.counselCaseCount),
        valueGetter: params => countNumber(params.row.counselCaseCount)
      },
      {
        field: 'tribunalCaseCount',
        headerName: "Tribunal Case Count",
        flex: 1,
        hide: isHidden(arbitratorColumns.tribunalCaseCount, true),
        renderCell: params => (params.row.tribunalCaseCount),
        valueGetter: params => countNumber(params.row.tribunalCaseCount)
      },
      {
        field: 'coachCount',
        headerName: "Coach Count",
        width: 120,
        hide: isHidden(arbitratorColumns.coachCount, true),
        renderCell: params => (params.row.coachCount),
        valueGetter: params => countNumber(params.row.coachCount)
      },
      {
        field: 'competitionCount',
        headerName: "Competition Count",
        width: 140,
        hide: isHidden(arbitratorColumns.competitionCount, true),
        renderCell: params => (params.row.competitionCount),
        valueGetter: params => countNumber(params.row.competitionCount)
      },      
      {
        field: 'occupation',
        headerName: "Occupation",
        width: 120,
        hide: isHidden(arbitratorColumns.occupation, true)
      },
      {
        field: 'profileUrl',
        headerName: "profileUrl",
        flex: 1,
        hide: isHidden(arbitratorColumns.profileUrl, true)
      },
      {
        field: 'selectedToRankJuryRoundClaimants',
        headerName: "Selected To Rank Jury Round Claimants",
        flex: 1,
        hide: isHidden(arbitratorColumns.selectedToRankJuryRoundClaimants, true)
      },
      {
        field: 'selectedToRankJuryRoundRespondents',
        headerName: "Selected To Rank Jury Round Respondents",
        flex: 1,
        hide: isHidden(arbitratorColumns.selectedToRankJuryRoundRespondents, true)
      },
      {
        field: 'applicationStatus',
        headerName: 'Application Status',
        width: 140,
        valueGetter: params => params.row.optInShareData !== null ? 'Completed' : 'In progress',
        hide: isHidden(arbitratorColumns.applicationStatus)
      },
      {
        field: 'status',
        headerName: 'Approval Status',
        width: 140,
        hide: isHidden(arbitratorColumns.status)
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 70,
        align: 'right',
        sortable: false,
        hide: isHidden(arbitratorColumns.action), 
        renderCell: (params) => (<ActionMenu
          status={params.row.status} 
          onAccept={()=>onSetArbitratorStatus(params.id, 'accepted')} 
          onHold={()=>onSetArbitratorStatus(params.id, 'hold')} 
          onDeny={()=>onSetArbitratorStatus(params.id, 'denied')} 
          onSilentlyHold={()=>onSetArbitratorStatus(params.id, 'hold', false)} 
          onSilentlyDeny={()=>onSetArbitratorStatus(params.id, 'denied', false)}
          />)
      },

    ]
    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={busy}
              rows={arbitrators}
              columns={columns}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}              
              localeText={{ toolbarExport: 'Export Arbitrators' }}              
              components={{
                Toolbar: CustomToolbar,
              }}
          />
        </div>
    )
  }


ArbitratorTable.propTypes = {
  arbitrators: PropTypes.arrayOf(PropTypes.object),
  getArbitrators: PropTypes.func,
  setArbitratorStatus: PropTypes.func,
  exportAllArbitratorConflicts: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      arbitrators: state.admin.arbitrators
    }),{
      getArbitrators: getAllArbitrators,
      setArbitratorStatus: setArbitratorStatus,
      exportAllArbitratorConflicts: exportAllArbitratorConflicts,
    })(ArbitratorTable)
