import React, { useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { NavLink } from 'react-router-dom'

import {CircularProgress} from "@material-ui/core";
import {connect} from "react-redux";
import {noop} from "lodash/fp";
import { loginUser } from "../../actions/userActions";

const { Box, Button } = require('@material-ui/core')

const Signin = ({ router={}, user, login = noop, history = { replace: () => console.warn('history prop not provided') } }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [pending, setPending] = useState(false)
  const returnTo = router.location.query.returnTo

  const handleSignin = async (e) => {
    e.preventDefault()
    setPending(true)
    try {
      const user = await login(username, password )
      if (user){
        setPending(false)
        let destination = '/'
        if (returnTo) {
            destination += `?returnTo=${returnTo}`
        }
        history.replace(destination)
      }else{
        setPending(false)
      }
    } catch (error) {
      setPending(false)
    }

  }

  return (
      <Container maxWidth='sm'>
        <form onSubmit={handleSignin}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4' style={{marginBottom:'1rem'}}>Sign In</Typography>
          <TextField
            value={username}
            label='Username'
            onChange={e => setUsername(e.target.value)}
          />
          <TextField
            value={password}
            label='Password'
            type='password'
            onChange={e => setPassword(e.target.value)}
          />
          <Box style={{ textAlign: 'center', margin: '2rem 0' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <div style={{display: 'flex', gap: '10px', minWidth: '38%'}}> 
              <Button type="submit" variant='contained' color='primary' disabled={pending}>
                Sign in
              </Button>
              { pending  && <CircularProgress size={35} /> }
              </div>
              <NavLink to='/login/password-reset' className={'color-dark'}>Forgot password?</NavLink>
              <NavLink to='/registration' className={'color-dark'}>Register</NavLink>
            </Box>
            {user.loginError && <Typography style={{ color: 'red' }}>{user.loginError}</Typography>}
          </Box>
        </Box>
        </form>
      </Container>
  )
}


export default connect(
    (state, ownProps) => ({
      router: state.router,
      user: state.user,
    }),{
      login: loginUser
    }
)(Signin)
