import React from 'react'
import { connect } from 'react-redux'
import { head } from 'lodash/fp'
import {
    TEAM_OWNER,
    ADMIN,
    ARBITRATOR, SCORER,
} from './consts/roles'
import { Redirect } from 'react-router'

export function Home({ user, router }) {
    // Figure out what dashboard to go to based on user roles
    // TODO What do we do if they have multiple roles?
    const returnTo = router.location.query.returnTo
    if (returnTo) {
        return <Redirect to={returnTo}/>
    }

    switch (head(user.roles)) {
        case ADMIN:
            return <Redirect to='/admin' />
        case TEAM_OWNER:
            return <Redirect to='/team-owner' />
        case ARBITRATOR:
            return <Redirect to='/arbitrator' />
        case SCORER:
            return <Redirect to='/admin/oral-arguments' />
        default:
            return <Redirect to='/registration'/>
    }
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
        router: state.router
    }),
)(Home)
