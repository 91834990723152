export const SAVE_TEAM_CLARIFICATION_REQUESTED = 'SAVE_TEAM_CLARIFICATION_REQUESTED'
export const SAVE_TEAM_CLARIFICATION_SUCCEEDED = 'SAVE_TEAM_CLARIFICATION_SUCCEEDED'
export const SAVE_TEAM_CLARIFICATION_FAILED = 'SAVE_TEAM_CLARIFICATION_FAILED'

export const GET_TEAM_REQUESTED = 'GET_TEAM_REQUESTED'
export const GET_TEAM_SUCCEEDED = 'GET_TEAM_SUCCEEDED'
export const GET_TEAM_FAILED = 'GET_TEAM_FAILED'

export const GET_TEAM_CLARIFICATION_CATEGORIES_REQUESTED = 'GET_TEAM_CLARIFICATION_CATEGORIES_REQUESTED'
export const GET_TEAM_CLARIFICATION_CATEGORIES_SUCCEEDED = 'GET_TEAM_CLARIFICATION_CATEGORIES_SUCCEEDED'
export const GET_TEAM_CLARIFICATION_CATEGORIES_FAILED = 'GET_TEAM_CLARIFICATION_CATEGORIES_FAILED'

export const GET_TEAM_CLARIFICATIONS_REQUESTED = 'GET_TEAM_CLARIFICATIONS_REQUESTED'
export const GET_TEAM_CLARIFICATIONS_SUCCEEDED = 'GET_TEAM_CLARIFICATIONS_SUCCEEDED'
export const GET_TEAM_CLARIFICATIONS_FAILED = 'GET_TEAM_CLARIFICATIONS_FAILED'

export const GET_IS_TEAM_CLARIFICATION_OPEN_REQUESTED = 'GET_IS_TEAM_CLARIFICATION_OPEN_REQUESTED'
export const GET_IS_TEAM_CLARIFICATION_OPEN_SUCCEEDED = 'GET_IS_TEAM_CLARIFICATION_OPEN_SUCCEEDED'
export const GET_IS_TEAM_CLARIFICATION_OPEN_FAILED = 'GET_IS_TEAM_CLARIFICATION_OPEN_FAILED'


export const GET_IS_TEAM_MEMBER_OPEN_REQUESTED = 'GET_IS_TEAM_MEMBER_OPEN_REQUESTED'
export const GET_IS_TEAM_MEMBER_OPEN_SUCCEEDED = 'GET_IS_TEAM_MEMBER_OPEN_SUCCEEDED'
export const GET_IS_TEAM_MEMBER_OPEN_FAILED =    'GET_IS_TEAM_MEMBER_OPEN_FAILED'

export const GET_IS_TEAM_REGISTRATION_OPEN_REQUESTED = 'GET_IS_TEAM_REGISTRATION_OPEN_REQUESTED'
export const GET_IS_TEAM_REGISTRATION_OPEN_SUCCEEDED = 'GET_IS_TEAM_REGISTRATION_OPEN_SUCCEEDED'
export const GET_IS_TEAM_REGISTRATION_OPEN_FAILED = 'GET_IS_TEAM_REGISTRATION_OPEN_FAILED'

export const SAVE_TEAM_MEMBER_REQUESTED = 'SAVE_TEAM_MEMBER_REQUESTED'
export const SAVE_TEAM_MEMBER_SUCCEEDED = 'SAVE_TEAM_MEMBER_SUCCEEDED'
export const SAVE_TEAM_MEMBER_FAILED =    'SAVE_TEAM_MEMBER_FAILED'

export const DELETE_TEAM_MEMBER_REQUESTED = 'DELETE_TEAM_MEMBER_REQUESTED'
export const DELETE_TEAM_MEMBER_SUCCEEDED = 'DELETE_TEAM_MEMBER_SUCCEEDED'
export const DELETE_TEAM_MEMBER_FAILED =    'DELETE_TEAM_MEMBER_FAILED'

export const GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_REQUESTED = 'GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_REQUESTED'
export const GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_SUCCEEDED = 'GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_SUCCEEDED'
export const GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_FAILED = 'GET_IS_TEAM_DOCUMENT_SUBMISSION_OPEN_FAILED'

export const UPLOAD_TEAM_DOCUMENT_REQUESTED = 'UPLOAD_TEAM_DOCUMENT_REQUESTED'
export const UPLOAD_TEAM_DOCUMENT_SUCCEEDED = 'UPLOAD_TEAM_DOCUMENT_SUCCEEDED'
export const UPLOAD_TEAM_DOCUMENT_FAILED = 'UPLOAD_TEAM_DOCUMENT_FAILED'

export const GET_SELECTED_TEAMS_PAIRINGS_REQUESTED = 'GET_SELECTED_TEAMS_PAIRINGS_REQUESTED'
export const GET_SELECTED_TEAMS_PAIRINGS_SUCCEEDED = 'GET_SELECTED_TEAMS_PAIRINGS_SUCCEEDED'
export const GET_SELECTED_TEAMS_PAIRINGS_FAILED = 'GET_SELECTED_TEAMS_PAIRINGS_FAILED'

export const SAVE_TEAM_ORDER_REQUESTED = 'SAVE_TEAM_ORDER_REQUESTED'
export const SAVE_TEAM_ORDER_SUCCEEDED = 'SAVE_TEAM_ORDER_SUCCEEDED'
export const SAVE_TEAM_ORDER_FAILED = 'SAVE_TEAM_ORDER_FAILED'

export const GET_TEAM_PAYMENT_HISTORY_REQUESTED = 'GET_TEAM_PAYMENT_HISTORY_REQUESTED'
export const GET_TEAM_PAYMENT_HISTORY_SUCCEEDED = 'GET_TEAM_PAYMENT_HISTORY_SUCCEEDED'
export const GET_TEAM_PAYMENT_HISTORY_FAILED = 'GET_TEAM_PAYMENT_HISTORY_FAILED'


export const GENERATE_TEAM_INVOICE_REQUESTED = 'GENERATE_TEAM_INVOICE_REQUESTED'
export const GENERATE_TEAM_INVOICE_SUCCEEDED = 'GENERATE_TEAM_INVOICE_SUCCEEDED'
export const GENERATE_TEAM_INVOICE_FAILED = 'GENERATE_TEAM_INVOICE_FAILED'

export const CREATE_STRIPE_PAYMENT_INTENT_REQUESTED = 'CREATE_STRIPE_PAYMENT_INTENT_REQUESTED'
export const CREATE_STRIPE_PAYMENT_INTENT_SUCCEEDED = 'CREATE_STRIPE_PAYMENT_INTENT_SUCCEEDED'
export const CREATE_STRIPE_PAYMENT_INTENT_FAILED = 'CREATE_STRIPE_PAYMENT_INTENT_FAILED'